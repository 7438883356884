import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Paginate from "vuejs-paginate-next";
import Vue3Dragscroll from 'vue3-dragscroll'
import { createHead } from '@unhead/vue/client';

const app = createApp(App)
const head = createHead();

app.use(head).use(Paginate).use(Vue3Dragscroll).use(store).use(router).mount('#app')
    
